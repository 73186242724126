import { Divider, Row, Descriptions, Card, Tag, Image } from 'antd'
import { ATTCHMENT_BASE_URL } from 'Util/Constant'
import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { setLoader } from 'Store/slices/commonSlice'
import Http, { type ApiErrorData } from 'Util/Http'
import { type AxiosError } from 'axios'
import { toast } from 'sonner'
import { useDispatch } from 'react-redux'
import { type IUser } from 'Util/Interface'

const UserDetail = () => {
  const [userData, setuserData] = useState<IUser>({
    id: '',
    userId: '',
    firstName: '',
    middleName: '',
    surName: '',
    dob: '',
    email: '',
    phone: '',
    sex: '',
    bloodGroup: '',
    hobbies: '',
    district: '',
    city: '',
    height: 0,
    weight: 0,
    marital: '',
    education: '',
    collegeName: '',
    occupation: '',
    position: '',
    companyName: '',
    income: '',
    aboutSelf: '',
    fatherName: '',
    fatherOccupation: '',
    fatherContact: '',
    motherName: '',
    motherOccupation: '',
    motherContact: '',
    noOfSibling: 0,
    sibling: '',
    maternalName: '',
    maternalDetails: '',
    residenceAddress: '',
    permanentAddress: '',
    property: '',
    expectation: '',
    otherContact: '',
    profilepic: '',
    documentImage: '',
    isVerified: 0,
    status: 0,
    isActive: 0,
    steps: 0,
    gender: '',
    designation: '',
    startAt: '',
    endAt: '',
    planId: '',
    paidCount: 0,
    created_at: '',
    updated_at: '',
  })
  const params = useParams()
  const dispatch = useDispatch()

  const fetch = useCallback(
    async (recordId: string) => {
      try {
        dispatch(setLoader(true))
        const response = await Http(`/user/${recordId}`)
        if (response.data?.status === 'success' && response?.data?.code === 200) {
          dispatch(setLoader(false))
          setuserData(response.data?.data)
        } else {
          dispatch(setLoader(false))
        }
      } catch (error: any) {
        const err = error as AxiosError<ApiErrorData>
        toast.error(err.response?.data?.message ?? 'Something Went Wrong')
        dispatch(setLoader(false))
      }
    },
    [dispatch]
  )

  useEffect(() => {
    if (params?.id) {
      void fetch(params?.id)
    }
  }, [fetch, params?.id])

  console.log('User Detaisl ', userData)

  return (
    <Card
      className="card-wrapper"
      title={
        <div className="row justify-between">
          <div>
            <h3>User Detail</h3>
          </div>
        </div>
      }
      style={{ padding: '1.25rem 1.25rem 0' }}
    >
      <Row>
        <Descriptions title="Basic Detail">
          <Descriptions.Item label="">
            <div className="row justify-center items-center">
              {userData?.profilepic ? (
                <Image crossOrigin="anonymous" src={`${ATTCHMENT_BASE_URL}${String(userData.profilepic)}`} alt="profile" height={100} width={100} />
              ) : (
                <div style={{ background: 'grey', height: 100, width: 100 }}></div>
              )}
            </div>
          </Descriptions.Item>
        </Descriptions>
      </Row>
      <Divider />
      <Row>
        <Descriptions title="Basic Detail">
          <Descriptions.Item label="First Name">{userData?.firstName}</Descriptions.Item>
          <Descriptions.Item label="Middle Name">{userData?.middleName}</Descriptions.Item>
          <Descriptions.Item label="Last Name">{userData?.surName}</Descriptions.Item>
          <Descriptions.Item label="Date of Birth">{userData?.dob}</Descriptions.Item>
          <Descriptions.Item label="Email">{userData?.email}</Descriptions.Item>
          <Descriptions.Item label="Phone No">{userData?.phone}</Descriptions.Item>
          <Descriptions.Item label="Gender">{userData?.sex}</Descriptions.Item>
          <Descriptions.Item label="Blood Group">{userData?.bloodGroup}</Descriptions.Item>
          <Descriptions.Item label="Hobbies">{userData?.hobbies}</Descriptions.Item>
          <Descriptions.Item label="District">{userData?.district}</Descriptions.Item>
          <Descriptions.Item label="City">{userData?.city}</Descriptions.Item>
        </Descriptions>
      </Row>
      <Divider />
      <Row>
        <Descriptions title="Personal Detail">
          <Descriptions.Item label="Height">{userData?.height}</Descriptions.Item>
          <Descriptions.Item label="Weight">{userData?.weight}</Descriptions.Item>
          <Descriptions.Item label="Marital Status">{userData?.marital}</Descriptions.Item>
          <Descriptions.Item label="Education">{userData?.education}</Descriptions.Item>
          <Descriptions.Item label="College Name">{userData?.collegeName}</Descriptions.Item>
          <Descriptions.Item label="Occupation">
            {userData?.occupation} <span>{userData?.position}</span>
          </Descriptions.Item>
          <Descriptions.Item label="Company Name">{userData?.companyName}</Descriptions.Item>
          <Descriptions.Item label="Income">{userData?.income}</Descriptions.Item>
          <Descriptions.Item label="Bio">{userData?.aboutSelf}</Descriptions.Item>
        </Descriptions>
      </Row>
      <Divider />
      <Row>
        <Descriptions title="Family Detail">
          <Descriptions.Item label="Father Name">{userData?.fatherName}</Descriptions.Item>
          <Descriptions.Item label="Father Occupation">{userData?.fatherOccupation}</Descriptions.Item>
          <Descriptions.Item label="Father Contact">{userData?.fatherContact}</Descriptions.Item>
          <Descriptions.Item label="Mother Name">{userData?.motherName}</Descriptions.Item>
          <Descriptions.Item label="Mother Occupation">{userData?.motherOccupation}</Descriptions.Item>
          <Descriptions.Item label="Mother Contact">{userData?.motherContact}</Descriptions.Item>
          <Descriptions.Item label="No. Of Sibling">{userData?.noOfSibling}</Descriptions.Item>
          <Descriptions.Item label="Sibling Details">{userData?.sibling}</Descriptions.Item>
          <Descriptions.Item label="Maternal Name">{userData?.maternalName}</Descriptions.Item>
          <Descriptions.Item label="Maternal Details">{userData?.maternalDetails}</Descriptions.Item>
          <Descriptions.Item label="Residence Address">{userData?.residenceAddress}</Descriptions.Item>
          <Descriptions.Item label="Permanent Address">{userData?.permanentAddress}</Descriptions.Item>
          <Descriptions.Item label="Property">{userData?.property}</Descriptions.Item>
          <Descriptions.Item label="Expectation">{userData?.expectation}</Descriptions.Item>
          <Descriptions.Item label="Other Contact">{userData?.otherContact}</Descriptions.Item>
        </Descriptions>
      </Row>
      <Divider />
      <Row>
        <Descriptions title="Account Info">
          <Descriptions.Item label="Is Verified">
            <Tag color={userData.isVerified === 1 ? 'green' : 'red'}>{userData.isVerified === 1 ? 'Verified' : 'Not Verified'}</Tag>
          </Descriptions.Item>
          <Descriptions.Item label="Is Active">
            <Tag color={userData.isActive === 1 ? 'green' : 'red'}>{userData.isActive === 1 ? 'Active' : 'InActive'}</Tag>
          </Descriptions.Item>
        </Descriptions>
      </Row>
    </Card>
  )
}

export default UserDetail
