import React, { useCallback, useEffect, useState } from 'react'
import { Button, Card, Col, Form, Image, Input, Row, Upload, type UploadProps } from 'antd'
import { type AxiosError } from 'axios'
import { setLoader } from 'Store/slices/commonSlice'
import { useAppDispatch } from 'Hooks/ReduxHook'
import { toast } from 'sonner'
import Http, { type ApiErrorData } from 'Util/Http'
import { HTTP_METHOD, type IAppVersion } from 'Util/Interface'
import { ATTCHMENT_BASE_URL } from 'Util/Constant'

const Setting: React.FC = () => {
  const [form] = Form.useForm()
  const [updateUploadImg, setUpdateUploadImg] = useState<any>('')
  const [uploadImg, setUploadImg] = useState<any>('')

  const dispatch = useAppDispatch()

  const fetch = useCallback(async () => {
    try {
      dispatch(setLoader(true))
      const response = await Http({
        url: '/setting',
        method: HTTP_METHOD.GET,
      })
      if (response.data?.status === 'success' && response?.data?.code === 200) {
        dispatch(setLoader(false))
        if (response?.data?.data?.qrcode) {
          setUpdateUploadImg(response?.data?.data?.qrcode)
        }
        form.setFieldsValue({
          appVersion: response?.data?.data?.appVersion,
          accountname: response?.data?.data?.accountname,
          phoneno: response?.data?.data?.phoneno,
          recordId: response?.data?.data?.id,
        })
      } else {
        dispatch(setLoader(false))
      }
    } catch (error: any) {
      const err = error as AxiosError<ApiErrorData>
      toast.error(err.response?.data?.message ?? 'Something Went Wrong')
      dispatch(setLoader(false))
    }
  }, [dispatch, form])

  useEffect(() => {
    void fetch()
  }, [fetch])

  const handleUpsert = async (param: IAppVersion) => {
    try {
      dispatch(setLoader(true))
      const formData = new FormData()
      formData.append('appVersion', param?.appVersion)
      formData.append('accountname', param?.accountname)
      formData.append('phoneno', param?.phoneno)
      formData.append('status', String(1))

      if (param?.qrcode && param?.qrcode[0]?.originFileObj) {
        formData.append('qrcode', param?.qrcode[0]?.originFileObj)
      }

      const response = await Http({
        url: `/setting/${param.recordId}`,
        method: HTTP_METHOD.POST,
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      if (response.data?.status === 'success' && response?.data?.code === 200) {
        toast.success(response?.data?.message)
        dispatch(setLoader(false))
        void fetch()
      } else {
        dispatch(setLoader(false))
        toast.error(response?.data?.message ?? 'Something Went Wrong')
      }
    } catch (error) {
      dispatch(setLoader(false))
      const err = error as AxiosError<ApiErrorData>
      toast.error(err.response?.data?.message ?? 'Something Went Wrong')
    }
  }

  const props: UploadProps = {
    accept: 'image/png, image/jpeg',
    name: 'thumbnail',
    className: 'avatar-uploader',
    showUploadList: false,
    maxCount: 1,
    beforeUpload: (file) => {
      const type = file.type.split('/')[1]
      if (type === 'png' || type === 'jpeg' || type === 'jpg') {
        return true
      } else {
        toast.error('Invalid type of file, Accept Only Jpg and Png')
        return false
      }
    },
    customRequest: (info) => {
      setUploadImg(info?.file)
    },
  }

  return (
    <>
      <div className="content-wrapper">
        <Card
          className="card-wrapper"
          title={
            <div className="row justify-between">
              <div>
                <h3>Setting</h3>
              </div>
            </div>
          }
          style={{ padding: '1.25rem 1.25rem 0' }}
        >
          <Row justify="center" align="middle">
            <Col lg={10} md={12} sm={24} xs={24}>
              <Form
                form={form}
                autoComplete="off"
                onFinish={(param: IAppVersion) => {
                  void handleUpsert(param)
                }}
                layout="vertical"
                requiredMark="optional"
              >
                <Form.Item
                  label="App Version"
                  name="appVersion"
                  rules={[
                    {
                      required: true,
                      message: 'Please Enter App Version!',
                      type: 'string',
                    },
                  ]}
                >
                  <Input className="custom-input" placeholder="Enter App Version" />
                </Form.Item>

                <Form.Item
                  label="Id"
                  name="recordId"
                  rules={[
                    {
                      required: true,
                      message: 'Please Enter Record Id!',
                      type: 'string',
                    },
                  ]}
                  hidden={true}
                >
                  <Input className="custom-input" placeholder="Enter Record Id" />
                </Form.Item>

                <Form.Item
                  label="Account Person Name"
                  name="accountname"
                  rules={[
                    {
                      required: true,
                      message: 'Please Enter Account Person Name!',
                      type: 'string',
                    },
                  ]}
                >
                  <Input className="custom-input" placeholder="Enter Account Person Name" />
                </Form.Item>

                <Form.Item
                  label="Mobile Number"
                  name="phoneno"
                  rules={[
                    {
                      required: true,
                      message: 'Please Enter Mobile Number!',
                    },
                  ]}
                >
                  <Input className="custom-input" placeholder="Enter Mobile Number" />
                </Form.Item>

                <Row>
                  <Col>
                    <Form.Item
                      label="QR Code"
                      name="qrcode"
                      rules={[
                        {
                          required: !updateUploadImg,
                          message: 'Please Upload QRCode!',
                        },
                      ]}
                      className="custom-input-label mb-6"
                      valuePropName="fileList"
                      getValueFromEvent={(event) => {
                        return event?.fileList
                      }}
                    >
                      <Upload {...props}>{uploadImg?.name ? <Button>{String(uploadImg?.name)}</Button> : <Button> Upload QR Code </Button>}</Upload>
                    </Form.Item>
                  </Col>
                  <Col>{updateUploadImg && <Image className="mx-5" height={200} width={200} src={`${ATTCHMENT_BASE_URL}${String(updateUploadImg)}`}></Image>}</Col>
                </Row>
                <Button type="primary" htmlType="submit" className="mt-3">
                  Update Now
                </Button>
              </Form>
            </Col>
          </Row>
        </Card>
      </div>
    </>
  )
}

export default Setting
