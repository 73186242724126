import React from 'react'
import {
  AppstoreOutlined,
  BankOutlined,
  CreditCardOutlined,
  LineHeightOutlined,
  PicCenterOutlined,
  ReadOutlined,
  SettingOutlined,
  SolutionOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons'

export interface SidebarNavigationItem {
  title: string
  key: string
  url?: string
  children?: SidebarNavigationItem[]
  icon?: React.ReactNode
}

export const sidebarNavigation: SidebarNavigationItem[] = [
  {
    title: 'Dashboard',
    url: '/',
    key: 'Dashboard',
    icon: <AppstoreOutlined />,
  },
  {
    title: 'City',
    url: '/city',
    key: 'City',
    icon: <BankOutlined />,
  },
  {
    title: 'Surname',
    url: '/surname',
    key: 'Surname',
    icon: <TeamOutlined />,
  },
  {
    title: 'Height',
    url: '/height',
    key: 'Height',
    icon: <LineHeightOutlined />,
  },
  {
    title: 'Weight',
    url: '/weight',
    key: 'Weight',
    icon: <PicCenterOutlined />,
  },
  {
    title: 'Education',
    url: '/education',
    key: 'Education',
    icon: <ReadOutlined />,
  },
  {
    title: 'Martial Status',
    url: '/martial',
    key: 'Martial Status',
    icon: <TeamOutlined />,
  },
  {
    title: 'Income',
    url: '/income',
    key: 'Income',
    icon: <CreditCardOutlined />,
  },
  {
    title: 'Designation',
    url: '/designation',
    key: 'Designation',
    icon: <SolutionOutlined />,
  },
  {
    title: 'Users',
    url: '/users',
    key: 'Users',
    icon: <TeamOutlined />,
  },
  {
    title: 'Guest User',
    url: '/guest-user',
    key: 'guest-user',
    icon: <TeamOutlined />,
  },
  {
    title: 'Setting',
    url: '/setting',
    key: 'Setting',
    icon: <SettingOutlined />,
  },
  {
    title: 'Profile',
    url: '/profile',
    key: 'Profile',
    icon: <UserOutlined />,
  },
]
