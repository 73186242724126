import React, { Suspense, lazy } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import RequireAuth from './RequireAuth'
import MainLayout from '../Component/MainLayout/MainLayout'
import LoginPage from '../Pages/LoginPage'
import NotFound from '../Pages/NotFound'
import ProfilePage from '../Pages/ProfilePage'
import MainLoader from 'Component/Common/MainLoader'
import Advertisement from '../Pages/Advertisement'
import City from '../Pages/City'
import Designation from '../Pages/Designation'
import Education from '../Pages/Education'
import Height from '../Pages/Height'
import Income from '../Pages/Income'
import MartialStatus from '../Pages/MartialStatus'
import Setting from '../Pages/Setting'
import Surname from '../Pages/Surname'
import Users from '../Pages/Users'
import Weight from '../Pages/Weight'
import GuestUsers from '../Pages/GuestUsers'
import UserDetail from '../Component/User/UserDetail'

const Dashboard = lazy(async () => await import('../Pages/Dashboard'))

// no lazy loading for auth pages to avoid flickering
const Logout = React.lazy(async () => await import('./Logout'))

export const DASHBOARD_PATH = '/'

export const AppRouter: React.FC = () => {
  const protectedLayout = (
    <RequireAuth>
      <MainLayout />
    </RequireAuth>
  )

  return (
    <BrowserRouter>
      <Suspense fallback={<MainLoader />}>
        <Routes>
          <Route path={DASHBOARD_PATH} element={protectedLayout}>
            <Route index element={<Dashboard />} />
            <Route path="/city" element={<City />} />
            <Route path="/surname" element={<Surname />} />
            <Route path="/height" element={<Height />} />
            <Route path="/weight" element={<Weight />} />
            <Route path="/education" element={<Education />} />
            <Route path="/martial" element={<MartialStatus />} />
            <Route path="/income" element={<Income />} />
            <Route path="/designation" element={<Designation />} />
            <Route path="/users" element={<Users />} />
            <Route path="/user/:id" element={<UserDetail />} />
            <Route path="/guest-user" element={<GuestUsers />} />
            <Route path="/setting" element={<Setting />} />
            <Route path="/advertisement" element={<Advertisement />} />
            <Route path="/profile" element={<ProfilePage />} />
          </Route>

          <Route path="login" element={<LoginPage />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  )
}
