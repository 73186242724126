export interface ILoginUser {
  email: string
  password: string
}

export interface IPagination {
  limit?: number
  skip?: number
  total?: number
}

export interface ICity {
  id: string
  title: string
  district: string
  status: number
  isActive: number
  created_at: string
  updated_at: string
}

export interface ISurname {
  id: string
  title: string
  status: number
  isActive: number
  created_at: string
  updated_at: string
}

export enum HTTP_METHOD {
  GET = 'GET',
  POST = 'POST',
  PATCH = 'PATCH',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

export interface IHeight {
  id: string
  title: string
  height: number
  status: number
  isActive: number
  created_at: string
  updated_at: string
}

export interface IWeight {
  id: string
  title: string
  weight: number
  status: number
  isActive: number
  created_at: string
  updated_at: string
}

export interface IEducation {
  id: string
  education: string
  status: number
  isActive: number
  created_at: string
  updated_at: string
}

export interface IMartialStatus {
  id: string
  title: string
  status: number
  isActive: number
  created_at: string
  updated_at: string
}

export interface IIncome {
  id: string
  income: string
  status: number
  isActive: number
  created_at: string
  updated_at: string
}

export interface IDesignation {
  id: string
  designation: string
  status: number
  isActive: number
  created_at: string
  updated_at: string
}

export interface IAppVersion {
  id: string
  appVersion: string
  status: string
  created_at: string
  updated_at: string
  recordId: string
  accountname: string
  phoneno: string
  qrcode: any
}

export interface IUpdatePassword {
  password: string
  password_confirmation: string
  old_password: string
}

export interface IUser {
  id: string
  userId: string
  firstName: string
  middleName: string
  surName: string
  dob: string
  email: any
  phone: string
  sex: string
  bloodGroup: string
  hobbies: string
  district: string
  city: string
  height: number
  weight: number
  marital: string
  education: string
  collegeName: string
  occupation: string
  position: string
  companyName: string
  income: string
  aboutSelf: string
  fatherName: any
  fatherOccupation: any
  fatherContact: any
  motherName: any
  motherOccupation: any
  motherContact: any
  noOfSibling: number
  sibling: any
  maternalName: any
  maternalDetails: any
  residenceAddress: any
  permanentAddress: any
  property: any
  expectation: any
  otherContact: any
  profilepic: any
  documentImage: any
  isVerified: number
  status: number
  isActive: number
  steps: number
  gender: any
  designation: any
  startAt: any
  endAt: any
  planId: any
  paidCount: number
  created_at: string
  updated_at: string
}

export interface IAdvertisement {
  id: string
  banner: string
  status: string
  created_at: string
  updated_at: string
}

export interface IAddAdvertisement {
  id: string
  thumbnail: any
  status: string
}

export interface IDashboard {
  city: number
  surname: number
  height: number
  weight: number
  designation: number
  education: number
  income: number
  martial: number
  users: number
}
