import React from 'react'
import { Layout, Row, Col, Avatar, Popover, Divider } from 'antd'
import { LogoutOutlined, MenuOutlined, UserOutlined } from '@ant-design/icons'
import { HeaderFullscreen } from './HeaderFullscreen'
import UserAvatar from '../../Assets/images/avatar.png'
import { Link } from 'react-router-dom'
import { useResponsive } from 'Hooks/useResponsive'
// import { t } from 'i18next'

const { Header } = Layout

const ProfileOverlay: React.FC = ({ ...props }) => {
  return (
    <div {...props} className="profile-overlay-wrapper">
      <div className="profile-overlay-text">
        <Link to="/profile">
          <span className="pr-2">
            <UserOutlined />
          </span>
          Profile
        </Link>
      </div>
      <Divider className="item-divider" />
      <div className="profile-overlay-text">
        <Link to="/logout">
          <span className="pr-2">
            <LogoutOutlined />
          </span>
          Logout
        </Link>
      </div>
    </div>
  )
}

interface IProps {
  showMenu: (value: boolean) => void
}

const TopHeader = ({ showMenu }: IProps) => {
  const { isTablet } = useResponsive()

  return (
    <Header className="header-layout">
      <Row align="middle">
        <Col span={4}>
          <div className="collape-menu">
            <MenuOutlined
              onClick={() => {
                showMenu(true)
              }}
              className="font-size-20"
            />
          </div>
        </Col>
        <Col span={20}>
          <Row justify="end" align="middle" gutter={[20, 5]}>
            <Col>
              <HeaderFullscreen />
            </Col>
            <Col className="cursor-pointer">
              <Popover content={<ProfileOverlay />} trigger="click">
                <Row justify="center" align="middle">
                  <Col>
                    <Avatar src={UserAvatar} alt="User" shape="circle" size={40} />
                  </Col>
                  {isTablet && (
                    <Col>
                      <div className="user-detail-title">{`Admin`}</div>
                    </Col>
                  )}
                </Row>
              </Popover>
            </Col>
          </Row>
        </Col>
      </Row>
    </Header>
  )
}

export default TopHeader
